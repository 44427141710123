import { defineComponent } from '@vue/composition-api';

import FlashList from '@/components/Article/FlashList';

export default defineComponent({
    components: { FlashList },
    setup() {
        return {};
    },
});
